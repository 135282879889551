<template>
  <div class="p-0 m-0 login-wrapper">
      <div class="left-bottom-img-wrapper p-0 m-0">
          <img class="left-bottom-bg" src="../assets/Login/loginLeftBG.png" />
          <img class="login-left-bg-img" src="../assets/Login/loginLeft.svg" />
      </div>
      <div class="right-bottom-img-wrapper p-0 m-0">
          <img class="right-top-bg" src="../assets/Login/loginRightBG.png" />
          <img class="login-right-bg-img" src="../assets/Login/loginRight.svg" />
      </div>
      <div class="row m-0 login-header">
          <div class="col-6 p-0 m-0 hecto-logo">
              <span @click="gotoLandingPage">
                  <img src="../assets/nav/HectocommerceLogo.svg">
              </span>
          </div>
          <div class="col-6 ml-auto text-left request-demo-btn">
            <w-button-input 
                :buttonClass="'request-demo'"
                :buttonStyle="'request-demo-style'"
                :label="'Request Demo'"
                @buttonClicked="requestDemoClick($event)"
            />
          </div>
          <div class="col-12 p-0 m-0 text-left back-btn">
            <!-- <span @click="gotoLandingPage">
                 <img src="../assets/Login/rightArrow.svg"> BACK
            </span> -->
          </div>
      </div>
      <div class="row my-auto login-form ">
        <div class="col-md-8 col-lg-7 col-xl-7 col-sm-10 m-auto text-center login-form-wrapper">
            <p class="login-title">Login To Manage Multiple Stores</p>
            <div class="row p-0 m-0">
                <div class="col-lg-6 col-xl-6 col-md-7 col-sm-10 p-0 m-0 mx-auto text-left">
                    <w-text-input
                        :labelName="'Email ID'"
                        :fieldType="'text'"
                        :disabled="false"
                        :defaultValue="emailValue"
                        :validation="'regex_Email'"
                        :newInputStyle="'loginInput'"
                        :placeholder="'Your Email'"
                        :fieldName="'Email Address'"
                        :labelStyle="'login-input-label'"
                        @textInputChange="getEmailAddress($event)"
                    />
                    <w-text-input
                        class="mt-4"
                        :labelName="'Password'"
                        :fieldType="'password'"
                        :disabled="false"
                        :defaultValue="passwordValue"
                        :validation="''"
                        :newInputStyle="'loginInput'"
                        :placeholder="'Your Password'"
                        :fieldName="'Email Address'"
                        :labelStyle="'login-input-label'"
                        @textInputChange="getPassword($event)"
                    />
                </div>
                <div class="col-12 p-0 m-0 mt-4 text-center">
                    <w-button-input 
                        :buttonClass="'auth-button'"
                        :buttonStyle="'auth-button-style'"
                        @buttonClicked="loginButton()"
                        :disabled="isloginDetail"
                        :label="'LOGIN'"
                    />
                </div>
                <div class="col-12 text-center forget-password">
                    Forgot Password ? <a class="click-here" @click="resetPassword">Click here</a>
                </div>
            </div>
        </div>
      </div>
      <div class="row p-0 m-0 copy-rights-wrapper">
          <div class="col-lg-6 col-xl-6 col-md-6 col-12 p-0 m-0 ml-auto request-demo-btn2">
              <w-button-input 
                :buttonClass="'request-demo2'"
                :buttonStyle="'request-demo-style'"
                :label="'Request Demo'"
                @buttonClicked="requestDemoClick($event)"
            />
          </div>
          <div class="col-lg-6 col-xl-6 col-md-6 p-0 pt-1 col-7 ml-auto copy-rights-content">Copyright © 2021 HectoCommerce, A DIATOZ Product, All rights reserved</div>
      </div>
    <w-modal
        :trigger="alert"
        :centered="true"
        :dontCloseWhenClickedOutside="true"
        :dontCloseWhenEscapePressed="true"
        :dontShowCloseIcon="true"
        :modalbuttonStyle="true"
        :alert="alert"
        :alertMsg="alertMsg"
        :alertType="alertType"
        @eventClose="closeModal()"
    />
  </div>
</template>

<script>
// import Img from "../assets/Login/loginLeftBG.png"
import TextInput from "../widgets/TextInput.vue";
import ButtonInput from "../widgets/InputButton.vue";
import { login } from "../Service/LoginService.js";
import Modal from "../widgets/ModalWidget.vue";

export default {
    components:{
        "w-text-input":TextInput,
        "w-button-input": ButtonInput,
        "w-modal": Modal,
    },
    data() {
        return {
            alertType: "",
            alert: false,
            alertMsg: "",
            isConfirmAlert: false,

            emailValue: "",
            passwordValue: "",
        };
    },

    computed: {
        isloginDetail() {
        if (!this.emailValue == "" && !this.passwordValue == "") {
            return false;
        }
        return true;
        },
    },

    methods: {
        getEmailAddress(event) {
            this.emailValue = event;
        },
        getPassword(event) {
            this.passwordValue = event;
        },

        loginButton() {
            let data = {
                username: this.emailValue,
                password: this.passwordValue,
            };
            login(data).then((res) => {
                if(res && res.access_token){
                    sessionStorage.setItem("token", res.access_token);
                    sessionStorage.setItem("companyID",res.company_id);
                    sessionStorage.setItem("userId",this.emailValue);
                    this.$router.push("/dashboard");
                }
            }).catch((err) => {
                this.alert = true;
                this.alertMsg = err.detail;
                this.alertType = "Failure";
                this.isConfirmAlert = false;
            });
            // if(this.emailValue == 'hectocommerce@diatoz.com' && this.passwordValue == 'admin'){
            //     this.$router.push("/dashboard");
            // }else{
            //   this.alert = true;
            //   this.alertMsg = 'Invalid credentials';
            //   this.alertType = "Failure";
            //   this.isConfirmAlert = false;
            // }

        },

        // redirect for request demo
        requestDemoClick(){
            this.$router.push("/pre-register")
        },

        registerButton() {
        // this.$emit("showRegistration");
            this.$router.push("/pre-register");
        },

        resetPassword(){
            this.$router.push("/forgotPassword")
        },

        // close modal
        closeModal() {
            this.alert = false;
            this.isConfirmAlert = false;
            this.alertMsg = "";
            this.alertType = "";
        },
        // redirect to landing page
        gotoLandingPage(){
            this.$router.push('/')
        },
    },
}
</script>

<style scoped>
    .back-btn{
        font-size: 18px;
        font-family: "bebas-kai";
        color: #A3A3A3;
    }
    .back-btn span{
        cursor: pointer;
    }
    /* .login-wrapper{
        overflow-y: hidden;
    } */
    .left-bottom-img-wrapper{
        position: absolute;
        bottom: 0px;
    }
    .left-bottom-img-wrapper > .left-bottom-bg{
        width:30rem;
    }
    .left-bottom-img-wrapper > .login-left-bg-img{
        position: absolute;
        top: 30%;
        left: 18%;
        width: 80%;
    }
    .right-bottom-img-wrapper{
        position: absolute;
        top: 0;
        right: 0px;
    }
    .right-bottom-img-wrapper > .right-top-bg{
        width:30rem;
    }
    .right-bottom-img-wrapper > .login-right-bg-img{
        position: absolute;
        top: 25%;
        left: 22%;
    }
    .login-wrapper{
        background-color: #fff;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }
    .login-wrapper::-webkit-scrollbar {
        display: none;
    }
    .login-header{
        padding: 1rem 6.1rem;
    }
    .login-header > .hecto-logo{
        font-family: "bebas-kai";
        font-size: 2rem;
        color:#464646;
    }
    .hecto-logo span{
        cursor: pointer;
    }
    .login-form{
        height: 80%;
    }
    .login-form-wrapper{
        padding:1rem 2rem;
    }
    .login-title{
        font-family: "bebas-kai";
        font-size: 3rem;
        color:#464646;
    }
    .forget-password, .click-here{
        color:#464646;
        font-family: "proxima-nova", sans-serif;
        font-size: 12px;
        font-weight: bold;
        margin-top: 20px;
    }
    .forget-password > .click-here{
        color:#DB393A;
        cursor: pointer;
    }
    .copy-rights-wrapper{
        display: flex;
        flex-direction: column;
        /* margin-top: 4rem !important; */
        padding-right: 6.1rem !important;
        position: absolute;
        right: 11rem;
        bottom: 2%;

    }
    .copy-rights-wrapper > .copy-rights-content{
        white-space: nowrap;
        font-size: 10px;
        color:#0475A1;
        text-align: right;
    }
    .request-demo-btn{
        display: flex;
        flex-direction: row-reverse;
    }
    .request-demo-btn2{
        display: none;
    }
    @media screen and (min-width: 1190px) and (max-width:1350px) {
        .left-bottom-img-wrapper > .left-bottom-bg{
            width:25rem;
        }
        .right-bottom-img-wrapper > .right-top-bg{
            width:30rem;
        }
        .login-title{
            font-size:2rem;
        }
    }
    @media screen and (min-width: 1000px) and (max-width: 1190px){
        .left-bottom-img-wrapper > .left-bottom-bg{
            width: 22rem;
        }
        .right-bottom-img-wrapper > .right-top-bg{
            width: 22rem;
        }
        .right-bottom-img-wrapper > .login-right-bg-img{
            width: 15rem;
        }
        .login-header{
            padding: 1rem 3rem;
        }
        .copy-rights-wrapper{
            padding-right: 3rem !important;
        }
        .login-title{
            font-size:2rem;
        }
    }
    @media screen and (min-width: 760px) and (max-width: 1000px){
        .left-bottom-img-wrapper > .left-bottom-bg{
            width: 15rem;
        }
        .right-bottom-img-wrapper > .right-top-bg{
            width: 15rem;
        }
        .right-bottom-img-wrapper > .login-right-bg-img{
            width: 10rem;
        }
        .login-header{
            padding: 1rem 3rem;
        }
        .copy-rights-wrapper{
            padding-right: 3rem !important;
        }
        .login-title{
            font-size:2rem;
        }
    }
    @media screen and (min-width: 320px) and (max-width: 760px){
        .login-form-wrapper{
            margin-top: 12% !important;
        }
        .left-bottom-img-wrapper > .left-bottom-bg{
            width: 8rem;
        }
        .right-bottom-img-wrapper > .right-top-bg{
            width: 9rem;
        }
        .right-bottom-img-wrapper > .login-right-bg-img{
            width: 6rem;
        }
        .login-header{
            padding: 1rem;
        }
        .copy-rights-wrapper{
            padding-right: 1rem !important;
            right:0rem;
        }
        .login-title{
            font-size: 1.3rem;
            margin-bottom: .5rem;
        }
        .request-demo-btn{
            display: none;
        }
        .request-demo-btn2{
            display: flex;
            flex-direction: row-reverse;
        }
        .copy-rights-wrapper{
            /* margin-top: 5% !important; */
           padding: 1rem !important; 
           text-align: left;
        }
        .copy-rights-wrapper > .copy-rights-content{
            /* text-align: left; */
            font-size: 7px;
            white-space: break-spaces;
        }
        .forget-password{
            margin-top:0px;
        }
    }
</style>